import React from 'react'
import { motion } from 'framer-motion'
import { Link, useNavigate } from 'react-router-dom'
import { UserAuth } from '../context/AuthContext'
import { supabase } from '../firebase'

const Navbar = () => {
  const { session} = UserAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await supabase.auth.signOut()
      navigate('/')

    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div className='w-full flex items-center justify-between p-4 z-[100] bg-white'>
      <Link to='/'>
        <h1 className='text-blue-600 text-xl md:text-4xl  font-bold cursor-pointer'>Rigi Landing Page Updater</h1>
      </Link>
     <div>
      {session?.user && (
        <div>
          <motion.button whileTap={{ scale: 0.75 }} onClick={handleLogout} className='bg-blue-600 px-6 py-2 rounded cursor-pointer text-base md:text-lg text-white focus:'>Logout</motion.button>
        </div>
      )}
      </div>

    </div>
  )
}

export default Navbar